<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-card>
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title>
          <span class="headline">Edit Branch</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="BranchData.Name"
            outlined
            dense
            :rules="[validators.required, validators.usernameValidator(BranchData.Name, 6)]"
            label="Name"
            placeholder="Full Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="BranchData.PhoneNumber"
            :rules="[validators.required, validators.lengthValidator(BranchData.PhoneNumber, 10)]"
            outlined
            dense
            type="number"
            label="Phone Number"
            placeholder="Phone Number"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-menu
            ref="OpenTimeMenu"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="BranchData.OpenTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="BranchData.OpenTime"
                label="Set Open Time"
                :prepend-icon="icons.mdiClockTimeFourOutline"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[validators.required]"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu1"
              v-model="BranchData.OpenTime"
              color="primary"
              full-width
              scrollable
              @click:minute="$refs.OpenTimeMenu.save(BranchData.OpenTime)"
            ></v-time-picker>
          </v-menu>
          <v-menu
            ref="CloseTimeMenu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="BranchData.CloseTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="BranchData.CloseTime"
                label="Set Close Time"
                :prepend-icon="icons.mdiClockTimeFourOutline"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[validators.required]"
              >
              </v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="BranchData.CloseTime"
              color="primary"
              full-width
              scrollable
              @click:minute="$refs.CloseTimeMenu.save(BranchData.CloseTime)"
            ></v-time-picker>
          </v-menu>
          <v-select
            v-model="BranchData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['branches']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <v-text-field
            v-model="BranchData.Description"
            outlined
            dense
            label="Description"
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { Edit } from '@core/api/Branch'
import { lengthValidator, required, usernameValidator } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Branch: { type: Object, required: true },
  },
  watch: {
    Branch(New) {
      this.BranchData = New
    },
  },
  setup(props, { emit }) {
    const blankBranchData = {
      Id: undefined,
      RestaurantId: props.ResId,
      Name: '',
      Description: '',
      PhoneNumber: '',
      DefualtAddons: [],
      OpenTime: null,
      CloseTime: null,
      Status: 'active',
    }
    const menu1 = ref(false)
    const menu2 = ref(false)

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const BranchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
    const resetBranchData = () => {
      BranchData.value = JSON.parse(JSON.stringify(blankBranchData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Edit(BranchData.value).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })

        resetBranchData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetBranchData()
    }
    return {
      menu1,
      menu2,
      close,
      resetBranchData,
      form,
      onSubmit,
      BranchData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator, usernameValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
