<template>
  <v-dialog :value="isDialogDoublecate" max-width="550px">
    <v-card>
      <v-card-title> Are you sure you want to Doublecate this item? </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="close"> Cancel </v-btn>
        <v-btn color="success" @click="doublecateItemConfirm"> OK </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-snackbar :light="$vuetify.theme.dark" v-model="isFormSubmittedSnackbarVisible">
        Can't Doublecate .
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { Doublecate } from '@core/api/Branch'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isDialogDoublecate',
    event: 'update:is-dialog-doublecate',
  },
  props: {
    isDialogDoublecate: {
      type: Boolean,
      required: true,
    },
    Id: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const isFormSubmittedSnackbarVisible = ref(false)

    const doublecateItemConfirm = () => {
      Doublecate({ Id: props.Id }).then(res => {
        if (res) {
          emit('update:is-dialog-doublecate', false)

          emit('refetch-data')
        } else {
          isFormSubmittedSnackbarVisible.value = true
        }
      })
    }
    const close = () => {
      emit('update:is-dialog-doublecate', false)
    }

    return {
      doublecateItemConfirm,
      close,
      isFormSubmittedSnackbarVisible,
    }
  },
}
</script>
