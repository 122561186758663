import request from '@core/utils/RequestApi'
import qs from 'qs'
export function GetById(query) {
  return request({
    url: '/Branch/GetById',
    method: 'get',
    params: query,
  })
}
export function GetByRestaurantId(query) {
  return request({
    url: '/Branch/GetByRestaurantId',
    method: 'get',
    params: query,
  })
}

export function Create(data) {
  return request({
    url: '/Branch/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Branch/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Branch/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Doublecate(data) {
  return request({
    url: '/Branch/Doublecate',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function ChangeStatus(data) {
  return request({
    url: '/Branch/ChangeStatus',
    method: 'post',
    data: qs.stringify(data),
  })
}
