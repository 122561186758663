<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-btn dark color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="20" class="mr-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Branch</span>
          </v-btn>
        </v-col>
        <v-col cols="9"></v-col>
      </v-row>

      <branch-add-new
        @refetch-data="fetchBranches"
        :res-id="parseInt($router.currentRoute.params.id)"
        v-model="isAddActive"
      ></branch-add-new>

      <delete-dialog @refetch-data="fetchBranches" :Id="branchTemp.Id" v-model="isDialogDelete"></delete-dialog>
      <doublecate-dialog
        @refetch-data="fetchBranches"
        :Id="branchTemp.Id"
        v-model="isDialogDoublecate"
      ></doublecate-dialog>

      <edit-dialog @refetch-data="fetchBranches" :Branch="branchTemp" v-model="isDialogEdit"></edit-dialog>

      <!-- Main Card -->
      <app-card-actions @refresh="fetchBranches">
        <template slot="title"> Branch List </template>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-5">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search"
              class="Orders-List-search me-3"
            ></v-text-field>

            <!-- status filter -->
            <v-col cols="12" sm="4">
              <v-select
                v-model="statusFilter"
                label="Status"
                :items="$store.state.status['branches']"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
                clearable
              ></v-select>
            </v-col>
          </div>
        </v-card-text>
        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="branchListTable"
          :options.sync="options"
          :server-items-length="totalbranchListTable"
          :loading="loading"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['branches'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['branches'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.Status }}
            </v-chip>
          </template>
          <!-- Actions -->
          <template #[`item.Actions`]="{ item }">
            <div class="demo-space-x">
              <v-btn icon color="error" @click.stop=";(isDialogDelete = !isDialogDelete), (branchTemp = { ...item })">
                <v-icon small>
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
              <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (branchTemp = { ...item })">
                <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
              </v-btn>
              <v-btn
                icon
                color="warning"
                @click.stop=";(isDialogDoublecate = !isDialogDoublecate), (branchTemp = { ...item })"
              >
                <v-icon small> {{ icons.mdiContentCopy }} </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
// eslint-disable-next-line object-curly-newline
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import { mdiContentCopy, mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import BranchAddNew from './BranchAddNew.vue'
import DeleteDialog from './DeleteDialog.vue'
import DoublecateDialog from './DoublecateDialog.vue'
import EditDialog from './EditDialog.vue'
import useBranchList from './useBranchList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: {
    BranchAddNew,
    DeleteDialog,
    EditDialog,
    DoublecateDialog,
    AppCardActions,
  },
  setup() {
    const {
      branchListTable,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      fetchBranches,
      statusFilter,
    } = useBranchList()
    const branchTemp = ref({ Id: 0 })
    const isAddActive = ref(false)
    const isDialogDelete = ref(false)
    const isDialogDoublecate = ref(false)
    const isDialogEdit = ref(false)
    return {
      branchListTable,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      isAddActive,
      fetchBranches,
      statusFilter,
      isDialogDelete,
      isDialogDoublecate,
      isDialogEdit,
      branchTemp,
      icons: {
        mdiPlus,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#Orders-List {
  .Orders-List-actions {
    max-width: 7.81rem;
  }
  .Orders-List-search {
    max-width: 10.625rem;
  }
  .Orders-List-status {
    max-width: 11.3rem;
  }
}
</style>
